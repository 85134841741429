<script>
export default {


 data() {
    return {
      notifications:[],
      loading: false,

  }
 },
  

 mounted(){
   document.body.classList.add("authentication-bg");
   this.getNotifications();
    this.getUnreadNotificationsCount();
  },

methods:{
      getNotifications(){
        this.loading = true;
        this.$axios.get("/user/notifications").then((response) => {
              this.notifications=response.data.payload;
              this.loading = false;
        }).catch(error => {
          this.handleErrorResponse(error.response, "error");
        });
                
    },
    getUnreadNotificationsCount(){
      
        this.$axios.get("/user/get-unread-notifications").then((response) => {
          this.unread_notifications_count=response.data.payload;
        }).catch(error => {
          this.handleErrorResponse(error.response, "error");
        });
                
    },
    readNotifications(){
      
        this.$axios.get("/user/read-all-notifications").then((response) => {
          this.notifications=response.data.payload;
          this.getNotifications();
          this.getUnreadNotificationsCount();
        }).catch(error => {
          this.handleErrorResponse(error.response, "error");
        });
                
    },
    clearAllNotification(){
      this.$axios.get("/user/delete-all-notifications").then((response) => {
      this.notifications=[];
        this.getUnreadNotificationsCount();
      }).catch(error => {
        this.handleErrorResponse(error.response, "error");
      });

    },
    readNotification(notification) {
      if (notification.read_at == null) {
        this.$axios
          .get("/user/read-notification", { params: { id: notification.id } })
          .then((response) => {
            this.getNotifications();
            this.getUnreadNotificationsCount();
          })
          .catch((error) => {
            this.handleErrorResponse(error.response, "error");
          });
      }
      let action =
      notification.data.action == null ? "/" : notification.data.action;
      let url = new URL(action)
      if (this.$route.path !== url.pathname){
        this.$router.push(url.pathname);
      }
    },
  },
  middleware: "authentication",
};
</script>

<template>
  <div>
    <APILoader v-if="loading" :loading="loading" class="align-middle"></APILoader>
    <h5 class="ms-3 fw-bold">Notifications</h5>
    <div v-if="notifications.length>0" class="card border">
      <div class="card-body">
        <div class="row">
          <div class="col-sm-3 col-md-2 mx-2">
              <a
                href="javascript:void(0)"
                @click="readNotifications"
                class="primaryColor fw-bold"
                >Mark as read</a
              >
          </div>
          <div class="col-sm-3 col-md-2 mx-auto me-0">
              <!-- <a @click="clearAllNotification"
              class="btn btn-sm btn-link font-size-14 "
              href="javascript:void(0)"
            >
              <i class="uil-trash-alt me-1"></i>
              Clear all 
            </a> -->
            <button class="btn secondary-bg text-white" @click="clearAllNotification"> <i class="uil-trash-alt me-1"></i> Clear All</button>
          </div>
        </div>

        <hr class="cl-hr-line">

        <div class="m-1" v-for="(notification ,index) in notifications" :key="index">
            <div class="media p-2" :class="{'alert-info':notification.read_at==null,'bg-body':notification.read_at!==null}">
              <div class="media-body">
                <a  href="javascript:void(0)"
                    @click="readNotification(notification)" class="text-reset notification-item">
                      <h6 class="mt-0 mb-1 fw-bold">
                        {{ notification.data.subject}}
                      </h6>
                </a>
                    <div class="font-size-12 text-muted">
                  <p class="mb-1 f-14">
                  {{ notification.data.message}}
                  </p>
                  <p class="mb-0 f-14 primaryColor">
                    <i class="mdi mdi-clock-outline"></i>
                    {{ formattedTime(notification.created_at)}}
                  </p>
                </div>
              </div>
            </div>
        </div>  
      </div>
           
    </div>
    <div v-else class="row">
      <div class="card d-flex align-items-center justify-content-center p-4">
        <span><i class="uil-exclamation-circle fs-1"></i></span>
        <p class="fs-5 fw-bold roboto">Empty Notifications</p>
      </div>
    </div>
  </div>
 
</template>
